import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import PickEmApp_Root from './App';
//import reportWebVitals from './reportWebVitals';
import {PickEm_Utilities} from './shared/utilities'


// TO BUILD : npm run build production 


const getThisWeeksData = async () =>{

  const url = new URL(window.location.href);
  const debugCheckString = url.searchParams.get("debug");
  const debugCheck =  debugCheckString === "true" ? true : false ;
  
  let thisNFLresponse,thisWeek,thisYear,seasonType;

  fetch(`/getConfig`).then((res) => res.json())
  .then(async(configObject) => {
    await fetch(configObject.nflAllScores)
    .then((res) => res.json())
    .then(async(json) => {
      PickEm_Utilities.vaderDebug.appLifecycle("index.js","NFL All Scores Response JSON :", json);
      thisNFLresponse = json && json ? json : null;
      thisWeek =  thisNFLresponse && thisNFLresponse.week && thisNFLresponse.week.number ? thisNFLresponse.week.number : null ;
      seasonType =  thisNFLresponse && thisNFLresponse.season && thisNFLresponse.season.type ? thisNFLresponse.season.type : null ;
      thisYear =  thisNFLresponse && thisNFLresponse.season && thisNFLresponse.season.year ? thisNFLresponse.season.year : null ;

      const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
      );
      
      root.render(
        <React.StrictMode>
          <PickEmApp_Root 
            debug={debugCheck}
            thisWeek={thisWeek}
            thisYear={thisYear}
            configObj={configObject}
            seasonType={seasonType}
          />
        </React.StrictMode>
      );
    });
  })   
}

getThisWeeksData();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
