import React from 'react';

//import bootstrap from 'bootstrap'
// Bootstrap CSS
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import '../App.css';

import { IVaderLeague,IVaderPick,IVaderRole,IVaderUser,IVaderWinLoss,IVaderConfigObj} from '../interfaces/vader'
import { IEspnEventWeek} from '../interfaces/espn'

const resultsUrls = [
    {
      Title:"Regular season results",
      URL:"https://nfl.vader.zone/RegSeason-2024-results.png"
    },

    {
      Title:"Preseason results",
      URL:"https://nfl.vader.zone/Preseason-2024-results.png"
    },
    {
      Title:"Each user pick vs Team count",
      URL:"https://nfl.vader.zone/Team-vs-user-picks.png"
    },
    {
      Title:"Excel data",
      URL:"https://nfl.vader.zone/2024_picks_pre_and_regular_season.xlsx"
    },
]

export interface IResultsProps{
  debug:boolean;
  //teamsOnBye:IEspnEventWeek | null,
}

export interface IResultsState{
  selectedWeek:string|number|null;
}


export default class PickEmApp_Results extends React.Component<IResultsProps, IResultsState> {
  
  constructor(props: IResultsProps, state:IResultsState) {
    super(props);
    this.state = {
      selectedWeek:null,
    }
  }
  
  public componentDidMount = async(): Promise<void> => {
    if(this.props.debug){console.log("PickEmApp_Results.componentDidMount() => this : ",this);}
  }


  public render(): React.ReactElement<IResultsProps> {
    
    if(this.props.debug){console.log("PickEmApp_Results.render() => this : ",this);}
    return <div> <h3>Results for Pre and Regular season - 2024</h3>
      {
        resultsUrls.map((resultObj,i)=>{
          return <div key={i} className="row">
              <div className="col-2" />
              <div className="col-8"><a target="_blank" rel="noopener noreferrer" href={resultObj.URL}>{resultObj.Title}</a></div>
              <div className="col-2" />
          </div>
        })
      }  

      <br />
      <br />
  </div>
  }

}